.user-settings {
    width: 75vw;
    margin: 0 auto;

    .row {
        gap: 1rem;
        flex-direction: row;
        flex-wrap: nowrap;
        --bs-gutter-x: 0;
    }

    .menu-col {
        padding: 0;
    }

    .form-block-btn {
        display: block;
    }

    .user-settings-menu,
    .user-settings-content {
        background-color: white;
        padding: 1rem;
        border-radius: 5px;
    }

    .user-settings-content {
        box-shadow: var(--default-shadow);

        .logo-wrapper {
            display: flex;
            align-items: end;
            flex-wrap: wrap;

            img {
                max-width: 350px;
                width: 100%;
            }
        }
    }

    .user-settings-menu {
        &.flex-column {
            gap: 1rem;
        }

        .nav-link {
            text-decoration: none !important;

            &:hover {
                color: var(--dark) !important;
                background-color: var(--light-grey) !important;

            }

            &.active {
                background-color: var(--light-grey) !important;
            }
        }
    }
}


@media screen and (max-width:768px) {
    .user-settings {
        width: 100%;

        .row {
            flex-wrap: wrap !important;
        }
    }
}