.full-overlay {
    background-color: rgba(255, 255, 255, 0.95);
    width: 100%;
    height: 100%;
    z-index: 1100;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    padding: 2rem;

    .bi {
        font-size: var(--icons-big) !important;
    }

    .full-img-wrapper {
        height: 80vh;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
        gap: 0.5rem;

        .full-img {
            max-height: 80vh;
            max-width: inherit;
            justify-self: center;
        }

        .small-screen-nav {
            width: 100%;
        }
    }

    .img-title {
        text-align: center;
        font-size: var(--h1);
    }

    .options {
        display: flex;
        gap: 0.5rem;
        justify-content: end;
    }

}

@media screen and (max-width: 576px) {
    .full-overlay {
        padding: 0.5rem;

        .img-title {
            font-size: 1rem;
        }

        .full-img {
            max-height: 70vh !important;
        }

        // Overwriting bootstrap flex-wrap default
        .row {
            flex-wrap: nowrap !important;
        }
    }


}