.gallery-form-styles {

    .form-check {
        margin-bottom: 1rem !important;
    }

    .form-switch {
        padding: 0 !important;
    }

    .form-check {
        display: flex !important;

        input {
            margin-left: auto !important;
            margin-bottom: 0 !important;
            order: 2;
        }

        label {
            order: 1;
        }
    }

    .form-check-input:checked {
        background-color: var(--bs-primary) !important;
        border-color: var(--bs-primary) !important;
    }

    .form-check-input {
        border-color: var(--bs-primary) !important;
    }

    .label-with-help {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    #hard-max-required-1 {
        display: none;
    }

}