.gallery-card {
    border: none !important;
    width: 200px;

    .card-body {
        margin-top: auto !important;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 0.5rem;
    }

    button {
        width: 100%;
    }

    .card-img-wrapper {
        position: relative;

        img {
            min-height: 125px;
            max-height: 150px;
            object-fit: cover;
        }

        background-color: var(--light-grey);

        .status-label {
            position: absolute;
            top: 0;
            right: 0;
            margin-top: 0.75rem;
            padding: 0rem 0.25rem 0rem 0.25rem;
            background-color: var(--bs-primary);
            font-variant: small-caps;
            font-size: small;

        }
    }
}

@media screen and (max-width: 495px) {
    .gallery-card {
        width: 100% !important;
        max-width: 100%;
    }
}