// General styles

// Setting h1 and h2 this way because the h1 size is also used for non h1 elements (such as the navbar title)
h1 {
  font-size: var(--h1);
}

h2 {
  font-size: var(--h2);
}

// Default icons size
.bi {
  font-size: var(--icons-size) !important;
}

// Smaller icon
.small-icon {
  font-size: .75rem !important;
  ;
}

// Default p margin
p {
  margin: 0 !important;
}

// Util class for required fields
.required {
  color: red;
}

// Default color for text elements (a dark grey instead of pure black)
p,
a,
h1,
h2,
.bi,
.bi.bi-button,
h3,
.navbar-text {
  color: var(--dark) !important;
  word-break: break-word;
}

// On hover, an icon button (that is not inside a button element) will get darker
.bi.bi-button:hover {
  color: black !important;
}

// Overwrite bootstrap's default behaviour when focusing form elements
.form-control:focus {
  border-color: var(--bs-primary) !important;
  box-shadow: none !important;
}

// Hiding bootstrap close button on modals
.btn-close {
  display: none;
}

// Ovewriting bs variables to allow for brand customization
.btn-primary {
  --bs-btn-bg: var(--bs-primary) !important;
  --bs-btn-border-color: var(--bs-primary) !important;
  --bs-btn-disabled-bg: var(--bs-primary) !important;
  --bs-btn-disabled-border-color: var(--bs-primary) !important;
  --bs-btn-active-border-color: var(--bs-primary) !important;
  --bs-btn-active-bg: var(--bs-primary) !important;

  --bs-btn-hover-border-color: var(--bs-primary-light) !important;
  --bs-btn-hover-bg: var(--bs-primary-light) !important;
}

.btn-outline-primary {
  --bs-btn-color: var(--bs-primary) !important;
  --bs-btn-border-color: var(--bs-primary) !important;
  --bs-btn-hover-bg: var(--bs-primary) !important;
  --bs-btn-hover-border-color: var(--bs-primary) !important;
  --bs-btn-active-bg: var(--bs-primary) !important;
  --bs-btn-active-border-color: var(--bs-primary) !important;
  --bs-btn-disabled-color: var(--bs-primary) !important;
  --bs-btn-disabled-border-color: var(--bs-primary) !important;
}

// default link appearance
a,
.btn-link {
  text-decoration: underline dotted var(--dark) !important;
  text-underline-offset: 0.3rem;

  &.dark-back {
    color: lightgrey !important;
    text-decoration: underline dotted lightgrey !important;

  }

  &:hover {
    color: var(--bs-primary) !important;
    cursor: pointer;
  }
}

// Handling icon-only buttons display and hover behaviour
.icon-button {
  padding: 0.5rem !important;
  background-color: transparent !important;
  border: none !important;
  height: fit-content;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}

// Utility class to turn the cursor into a pointer
.pointer {
  cursor: pointer;
}

// Utility class to italicize a text
.italic {
  font-style: italic;
}

// utility class to turn cursor into a text cursor
.text-cursor {
  cursor: text;
}

// Makes an element stand on top of all the others
.top-alert {
  z-index: 1200 !important;
}

// Default padding and margin utilities. 1rem is the default.
.padding-right {
  padding-right: 1rem;
}

.margin-right {
  margin-right: 1rem;
}

.margin-left {
  margin-left: 1rem;
}

// Utilities to align text
.text-align-end {
  text-align: end;
}

.text-align-center {
  text-align: center;
}

// Main content back for pro
.main-content-pro {
  min-height: calc(85vh - var(--navbar-size));
  padding: 2rem;
  background-color: var(--light-grey);
}

// Custom row (prefixed "-c" to avoid overlap with Bootstrap class)
.c-row {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap: wrap;

  .end-elem-responsive {
    margin-right: auto !important;
  }

  &.align-top {
    align-items: start;
  }

  &.align-center {
    align-items: center;
  }
}

// Give an element a margin-top of the same size as the default navbar size
.navbar-margin-top {
  margin-top: var(--navbar-size);
}

.card-shadow {
  box-shadow: var(--default-shadow);
}

// Default form-group margin
.form-group {
  margin-bottom: 1rem !important;
}

// Responsive styles for mobile (using Bootsrap's smallest breakpoint)
@media screen and (max-width: 576px) {
  .navbar-margin-top {
    margin-top: 0 !important;
  }
}