.main-content-gal {
  min-height: calc(85vh - var(--navbar-size));
  padding: 2rem;

  .gallery-header {
    margin-bottom: 1rem;

    .gallery-header-left {
      max-width: 100%;
    }
  }
}

@media screen and (min-width:768px) {
  .gallery-header {
    .gallery-header-left {
      max-width: 75% !important;
    }
  }
}