// Overwriting Bootstrap variables
$theme-colors: (
  "primary": #ff8b00,
);

// Custom variables
:root {
  --dark: #3C3939;
  --light-grey: #f8f8f8;
  --medium-grey: #dee2e6;
  --default-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.25);
  --navbar-size: 76px;
  --h1: 1.5rem;
  --h2: 1.25rem;
  --h1-big: 3rem;
  --icons-size: 1.5rem;
  --nav-icons-size: 1.5rem;
  --icons-big: 2rem;
}


@import '~bootstrap/scss/bootstrap.scss';
@import 'bootstrap/dist/css/bootstrap.min.css'