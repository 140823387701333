.ref-container {
  position: relative;
}

.help-icon {
  font-size: 1rem !important;
  position: relative;
}

.tooltip {
  position: absolute;
  left: 6px; // default alignment

  div.tooltip-inner {
    background-color: white !important;
    color: var(--dark) !important;
    border-radius: 0 5px 5px 5px;
    box-shadow: var(--default-shadow);
    max-width: 400px !important;
    width: 400px !important;
    text-align: left;
  }

  div.tooltip-arrow {
    display: none !important;
  }

  // Force left alignment
  &.align-tooltip-left {
    left: -190px !important;
  }

  // Force right alignment
  &.align-tooltip-right {
    left: 6px !important;
  }
}

@media screen and (max-width:768px) {
  .tooltip {
    div.tooltip-inner {
      max-width: 200px !important;
      width: 200px !important;
    }
  }
}

@media screen and (min-width:576px) and (max-width:768px) {
  .tooltip {
    left: -190px !important; // default alignment for this specific screen size

    div.tooltip-inner {
      border-radius: 5px 0 5px 5px;
    }
  }
}