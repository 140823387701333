.navbar {
  background-color: white;
  box-shadow: var(--default-shadow);
  padding: 2rem !important;
  max-height: var(--navbar-size);
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .navbar-nav {
    background-color: white;
  }

  .float-badge {
    margin-right: 1rem;
    position: relative;

    .badge {
      position: absolute !important;
      top: 2px !important;
      left: 20px;
    }
  }

  .navbar-text.title {
    font-size: var(--h1);
  }

  .nav-link.spinner-wrapper {
    display: flex !important;
    align-items: center !important;
  }

  .navbar-end {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 100%;
  }

  a.dropdown-toggle {
    padding: 0 !important;

    &:hover {
      color: black !important;
    }
  }

  a.dropdown-item {
    text-decoration: none !important;
  }

  .user-button {
    margin-left: 1rem;
  }
}



@media screen and (max-width: 576px) {
  .navbar {
    padding: 1rem !important;
    max-height: 100% !important;
    position: relative !important;

    .navbar-text {
      font-size: 1rem !important;
    }

    .navbar-nav {
      flex-direction: row;
      width: 100%;

      .dropdown-menu {
        position: absolute;
      }

      button,
      .user-button {
        flex-grow: 1 !important;
      }

      .user-button {
        margin-left: 0;
      }
    }

    .float-badge {
      .badge {
        left: 80px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .navbar {
    .float-badge {
      .badge {
        left: 60px;
      }
    }
  }
}