.footer {
    width: 100%;
    height: 15vh;
    background-color: var(--dark);
    padding: 2rem;

    p {
        color: lightgrey !important;
    }
}

@media screen and (max-width: 576px) {
    .footer {
        height: auto !important;
    }
}