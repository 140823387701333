.image-card-wrapper {
    position: relative;
    flex-grow: 1;
    height: 25vh;

    &:last-child {
        flex-grow: 30;
    }

    .gal-img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        max-width: inherit;
        width: 100%;
    }

    .fav-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0.75rem;
        padding: 0rem 1rem 0rem 0.25rem;
        background-color: rgba(255, 255, 255, 0.60);
    }

    .hover {
        position: absolute;
        top: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 100;
        width: 100%;
        height: 100%;

        .hover-options {
            width: 100%;
            justify-content: end;
            display: flex;
            margin-top: 0.75rem;
            padding: 0rem 1rem 0rem 0.25rem;
            gap: 0.5rem;

            .was-downloaded {
                color: blue;
            }
        }


    }
}

// Responsive styles for mobile (using Bootsrap's smallest breakpoint)
@media screen and (max-width: 576px) {
    .image-card-wrapper {
        max-height: 25vh;
        height: auto;
    }
}