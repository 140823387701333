.user-gal-menu {
    background-color: white;

    .c-row {
        flex-wrap: nowrap;
    }

    .menu-elem {
        max-width: inherit;

        p {
            margin-bottom: 1rem !important;
        }

        img {
            width: 100%;
            margin-bottom: 1rem;

            &.cover-back {
                background-color: var(--light-grey);
            }
        }
    }

    .gal-specs {
        border-bottom: thin solid var(--medium-grey);
    }

    // Hide the default bs dropdown arrow
    .dropdown-toggle::after {
        display: none !important;
    }

    a.dropdown-item {
        text-decoration: none !important;
    }

    h2 {
        font-size: 1.5rem;
    }
}

.main-content-pro.user-gal {
    padding: 0;
}

.container-fluid {
    min-height: inherit;

    #user-gal-main-row {
        min-height: inherit;
    }

}

.user-gal-col {
    padding: 2rem !important;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.nav-tabs {
    gap: 1rem;

    .nav-link {
        text-decoration: none !important;
        color: var(--dark);

        &:hover {
            color: var(--dark) !important;
            border-bottom: thick solid var(--bs-primary) !important;
        }
    }

    // Overriding bs styles to remove tab borders and keep only the bottom line
    --bs-nav-tabs-link-active-bg: transparent !important;
    --bs-nav-tabs-border-width: 0 !important;
    border-bottom: thin solid var(--medium-grey) !important;
    --nav-link-transition: border-color .100s ease-in-out;

    // Overriding active tab bs styles to make sure the line looks flat
    .nav-link.active {
        border-bottom: thick solid var(--bs-primary) !important;
    }
}

.gal-menu-input {
    width: 100%;

    .icon-button {
        border: thin solid var(--medium-grey) !important;
    }
}

.card-img-wrapper {
    position: relative;

    .status-label {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0.75rem;
        padding: 0rem 0.25rem 0rem 0.25rem;
        background-color: var(--bs-primary);
        font-variant: small-caps;
        font-size: small;
    }
}

.maxFavs-wrapper {
    width: 100% !important;
}

.form-group.row {
    flex-wrap: wrap;
}