// Page sections styles
.main-content-client-home {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: calc(50vh - var(--navbar-size));

  &.no-header {
    height: calc(85vh - var(--navbar-size));
    flex-direction: column;

    img {
      max-height: 15vh;
    }
  }

  // Align the button right
  .b-container {
    display: flex;
    margin: 1rem 0;
    justify-content: end;
  }

  // On desktop, limit the width of the main-content to avoid an illegible spread
  .content-wrapper {
    max-width: 50%;
    padding: 1rem 0;
  }

  .content-wrapper-justify-center {
    max-width: 50%;
    flex-direction: column;
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  h1 {
    // H1 should be bigger on this page
    font-size: var(--h1-big);
  }

}

.inside-header {
  height: calc(35vh + var(--navbar-size));
  width: 100%;
  background-size: cover;
  // background-attachment: fixed;
}

@media screen and (max-width: 576px) {
  .main-content-client-home {
    padding: 1rem !important;
    height: auto !important;

    &.no-header {
      height: 70vh !important;
    }

    .content-wrapper {
      max-width: 100%;
    }
  }

  .inside-header {
    height: calc(25vh + var(--navbar-size));
  }


}