.confirm-alert {
    .form-check {
        display: block !important;
        padding: 0px;
        margin-top: 1rem;

        .form-check-input {
            margin-right: 0.5rem;
            margin-left: 0;
        }
    }
}