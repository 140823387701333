#firstPane {
    background-color: var(--dark);

    .carousel {
        width: 100%;
        margin-top: var(--navbar-size);
        height: calc(100vh - var(--navbar-size));


        img {
            max-width: 75%;
            height: 75%;
            margin: 2rem auto 1rem;
            //   height: calc(100vh - var(--navbar-size));
            // object-fit: cover;
        }

        .carousel-indicators {
            bottom: 6rem !important;
        }

        .carousel-item {
            display: flex;
            height: 100%;

        }

        .carousel-inner {
            display: flex;
            align-items: center;
            height: 100%;
        }


        .carousel-caption {
            bottom: 8rem !important;

            p {
                color: white !important;
            }

            //      background-color: white;
        }

    }

    .cover-wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

    }

    .cover-text-wrap {
        position: absolute;
        bottom: 0rem;
        width: 100%;
        padding: 2rem;
        flex-direction: column;
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 100;
        background-color: var(--light-grey);

        .cover-text {
            width: 50%;
            justify-content: center;
        }
    }

    .inner-nav-btn {
        text-decoration: none !important;
        color: var(--bs-primary) !important;

        &:hover {
            color: var(--dark) !important;
        }
    }
}

#photographer,
#photographerCustomer,
#beta {
    position: absolute;
    top: calc(0px - var(--navbar-size));
}

#photographerPane,
#photographerCustomerPane,
#betaPane {
    position: relative;
    display: flex;
    justify-content: center;
}

.pane-wrap2 {
    width: 50%;
    padding: 2rem 0;
    flex-direction: column;
    display: flex;

    p {
        margin-bottom: 1rem !important;
    }
}

.pane-wrap3 {
    width: 75%;
    padding: 2rem 0;

    p {
        margin-bottom: 1rem !important;
    }

    a {
        display: block;
    }
}


#betaPane {
    background-color: var(--light-grey);
}



@media screen and (max-width: 991px) {

    .pane-wrap2,
    .pane-wrap3 {
        width: 100% !important;
    }

    .pane-wrap2,
    .pane-wrap3 {
        padding: 1.5rem;
    }
}

@media screen and (max-width: 1300px) {
    #firstPane {
        .cover-text {
            width: 100% !important;
        }

        .carousel {

            img {
                max-width: 90% !important;

            }
        }
    }
}

@media screen and (max-width: 1100px) {
    #firstPane {

        .carousel {
            .carousel-inner {
                align-items: start !important;
                height: auto !important;
            }

            .carousel-item {
                height: 80vh !important;
                align-items: start !important;
            }

            .carousel-caption {
                bottom: 2rem !important;
            }

            img {
                max-width: 100% !important;
                // width: 100vh !important;
                border: thick solid var(--dark);
                height: auto !important;
                margin: 0 !important;
                //   height: calc(100vh - var(--navbar-size));
                object-fit: contain !important;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    #firstPane {
        .carousel {
            margin-top: 0;

            .carousel-caption {
                bottom: 4rem !important;
            }

            .carousel-indicators {
                bottom: 9rem !important;
            }
        }
    }
}

@media screen and (max-height: 850px) {
    #firstPane {
        .carousel {
            .carousel-caption {
                bottom: 8rem !important;
            }

            .carousel-indicators {
                bottom: 11rem !important;
            }
        }
    }
}