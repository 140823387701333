.main-content-pro-login {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    height: calc(85vh - var(--navbar-size));

    // Align the button right
    .b-container {
        display: flex;
        margin: 1rem 0;
        justify-content: end;
    }

    // On desktop, limit the width of the main-content to avoid an illegible spread
    .content-wrapper-justify-center {
        max-width: 50%;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        img {
            max-height: 15vh;
            height: auto;
            width: 100%;
        }

        form {
            width: 100%;
        }

        input {
            margin-bottom: 1rem;
        }
    }

}

@media screen and (max-width: 576px) {
    .main-content-pro-login .content-wrapper-justify-center {
        max-width: 100%;
        width: 100%;
        padding: 1rem;
    }
}