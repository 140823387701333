.user-image-card {
    border: none !important;
    max-width: 300px;

    .card-body {
        margin-top: auto !important;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 0.5rem;

        .card-title {
            word-break: break-word;
        }

        .c-row {
            flex-wrap: nowrap !important;
        }
    }
}

.user-image-card-wrapper {
    position: relative;
    flex-grow: 1;
    height: 20vh;

    .gal-img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        max-width: inherit;
        width: 100%;
        border-radius: 5px 5px 0 0;
    }

    .fav-icon {
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 0.75rem;
        padding: 0rem 1rem 0rem 0.25rem;
        background-color: rgba(255, 255, 255, 0.60);
    }

    .hover {
        position: absolute;
        top: 0;
        background-color: rgba(255, 255, 255, 0.75);
        z-index: 100;
        width: 100%;
        height: 100%;

        .hover-options {
            width: 100%;
            justify-content: end;
            display: flex;
            margin-top: 0.75rem;
            padding: 0rem 1rem 0rem 0.25rem;
            gap: 0.5rem;
        }


    }
}

@media screen and (max-width:500px) {
    .user-image-card {
        width: 100% !important;
        max-width: 100%;
    }
}